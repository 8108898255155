@use "assets/styles/global" as *;

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $overlay-z-index;
}

.modal-background {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
}

.modal {
  position: absolute;
  top: 20%;
  left: calc(50% - 200px);
  width: 400px;
  padding: 2rem 3rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 12px;
  
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .countdown-actions {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 1rem;
  }
}
