@use "assets/styles/global" as *;

.home-background-image,
.home-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.home-background-image {
  background-image: url("../../assets/img/background.jpg");
  background-position: bottom left;
  background-size: cover;
}

.home-background-overlay {
  background: transparent linear-gradient(180deg, #ffffff 0%, rgb(255 255 255 / 40%) 44%, rgb(255 255 255 / 0%) 97%) 0% 0% no-repeat padding-box;
}
