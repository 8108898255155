@use "assets/styles/global" as *;

.action-card {
  border-radius: 29px;
  margin: 0 30px;

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p {
    text-align: center;
  }
}

.primary-action-card {
  padding: 220px 30px;

  p {
    font-size: 80px;
    line-height: 1.25;
  }
}

.secondary-action-card {
  padding: 30px 40px;
  cursor: pointer;

  .card-background {
    opacity: 0.78;
  }

  .card-content {
    gap: 2rem;
  }

  .preface {
    font-size: 28px;
    line-height: 1.25;
  }

  .title {
    font-size: 44px;
    line-height: 2;
  }
}
