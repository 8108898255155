@use "assets/styles/global" as *;

.product-skeleton {
  display: none; // TEMP

  background-color: #dbdbdb;
  box-shadow: 0px 20px 99px #b9b9b929;
  height: 550px;
  border-radius: 15px;
}
