@use "assets/styles/global" as *;

.mini-basket {
  position: relative;
  z-index: $base-z-index;
  padding: 30px $gutter;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 14px;
  box-shadow: 0px 20px 99px #b9b9b982;

  display: flex;
  justify-content: space-between;

  .mini-basket-toggle {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    flex-grow: 1;

    .arrow-icon {
      color: $text-color;
      transform: rotate(270deg);
      margin-top: -8px;
    }

    p {
      font-size: 36px;
    }
  }

  .place-order-button {
    font-size: 36px;
    height: 100px;
    min-width: 300px;
    padding: 0 50px;

    gap: 100px;

    span:nth-child(2) {
      font-weight: 400;
    }
  }

  .shopping-bag {
    position: relative;

    .count-indicator {
      position: absolute;
      top: -8px;
      right: -8px;
      background-color: $primary-color;
      border-radius: 50%;
      width: 22px;
      height: 22px;

      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 13px;
        font-weight: 600;
        color: white;
      }
    }
  }
}
