@use "assets/styles/global" as *;

.basket {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: $overlay-z-index;
}

.basket-background-layer {
  position: absolute;
  height: 100%;
  top: -1rem;
  left: -1rem;
  right: -1rem;
  background-color: $background-color;
}
