@use "assets/styles/global" as *;

.error-boundary {
  box-sizing: border-box;
  padding: 5rem 1rem;
  text-align: center;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include md {
    padding: 10% 2rem;
  }

  .error-boundary-title {
    font-size: 3rem;
  }

  .button {
    margin-top: 1rem;
  }

  .insights-trace-text {
    opacity: 0.5;
    font-size: 14px;
    margin: 1rem 0;
  }
}
