@use "assets/styles/global" as *;

.menu-page {
  .page-content {
    padding: 75px $gutter;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;

  }
}
