@use "assets/styles/global" as *;

.payment-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.payment-content {
  background-color: #fff;
  border-radius: 21px;
  box-shadow: 0px 24px 64px #45454514;
  padding: 45px 45px 80px;
  margin: 0 $gutter;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  text-align: center;

  .cancel-button {
    align-self: flex-end;
    font-size: 30px;
    height: 65px;

    &:disabled {
      opacity: 0.4;
    }

    svg {
        height: 20px;
        color: $danger-color;
    }
  }

  h1 {
    font-size: 60px;
    font-weight: 400;
  }

  .payment-error-title {
    margin-top: 1.5rem;
  }
  .payment-status-name {
    font-size: 18px;
    opacity: 0.6;
    margin-top: 1.5rem;
    margin-bottom: -20px;
  }

  .action-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;

    button.button {
      padding: 0 40px;
    }
  }

  img {
    height: 180px;
  }

  .description {
    p:nth-child(1) {
      font-size: 40px;
      color: #495c6e;
    }

    p:nth-child(2) {
      font-size: 60px;
      font-weight: 500;
      line-height: 1.5;
    }
  }
}
