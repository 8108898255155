@use "assets/styles/global" as *;

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $overlay-z-index;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 15px $gutter;
  background-color: #fff;
  cursor: pointer;

  p {
    font-size: 30px;
    color: #eb140d;
  }

  svg {
    color: #eb140d;
    height: 24px;
  }
}
