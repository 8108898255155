.input {
  background-color: #fff;
  color: #000;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 12px 16px;
  box-sizing: border-box;
  font-size: 16px;

  &:focus {
    border: none;
    outline: 2px solid #3c3c3c;
  }

  &:disabled,
  &.disabled {
    background-color: #f4f4f4;
    opacity: 0.5;
  }
}
