@use "assets/styles/global" as *;

$offset: 50px;

.basket-content {
  position: relative;
  background-color: #f7f7f7;
  box-shadow: 0px 8px 24px #17171729;
  padding: calc($offset + 50px) $gutter 50px;
  margin-top: -$offset;
  border-radius: 14px;

  .content-header {
    display: flex;
    justify-content: space-between;
    align-self: center;
  }

  .content-title {
    font-size: 40px;
    color: #000000;
  }

  .product-count {
    background-color: #fff;
    border: 2px solid #d8d8d8;
    height: 50px;
    width: 50px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: #888888;
      font-size: 32px;
    }
  }

  .product-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 60px;
  }

  .basket-product {
    border-radius: 21px;
    box-shadow: 0px 6px 16px #77777714;
    padding: 30px;
    background-color: white;

    display: flex;
    gap: 2rem;

    &.without-price {
      align-items: center;

      .product-price {
        display: none;
      }
    }

    .product-name-wrapper {
      flex-grow: 1;
      padding-left: 20px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .product-name {
      width: 100%;
      font-size: 30px;
      font-weight: 500;
      color: #000;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .product-price {
      font-size: 30px;
      font-weight: 600;
      margin-top: 10px;
    }

    .product-delete-icon {
      background-color: #ffebeb;
      padding: 5px;
      height: 56px;
      width: 56px;
      border-radius: 6px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}
