@use "assets/styles/global" as *;

.popup-alert {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: $overlay-z-index;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 24px #17171729;

  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    font-size: 18px;
    font-weight: 500;
  }
}
