@use "assets/styles/global" as *;

.fullscreen-alert {
  background-color: #ecebeb;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $overlay-z-index;

  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-animation-box {
  position: absolute;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 60%, rgba(#fff, 0.5) 80%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.alert-content {
  position: relative;
  background-color: #fff;
  border-radius: 14px;
  padding: 30px 50px;
  box-shadow: 0px 20px 99px #b9b9b982;

  p.alert-title, p.alert-description {
    text-align: center;
    line-height: 2;
  }

  p.alert-title {
    font-weight: 500;
    font-size: 40px;
  }

  p.alert-description {
    font-weight: 400;
    font-size: 18px;
  }
}
