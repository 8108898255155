$primary-color: #062849;
$text-color: #062849;

$background-color: #f7f7f7;
$danger-color: #eb150e;

$base-z-index: 1;
$overlay-z-index: 10;

// Spacing
$page-width: 100vw;
$gutter: 50px;

// Font
$main-font: "Poppins", sans-serif;
