@use "assets/styles/global" as *;

.product {
  display: grid;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 20px 99px #b9b9b929;
  padding: 24px;
  box-sizing: border-box;

  .product-image {
    height: 321px;
    width: 100%;
    object-fit: cover;
    background-color: #c3c3c3;

    border-radius: 9px;
    overflow: hidden;
  }

  .product-name {
    font-size: 34px;
    font-weight: 500;
    margin: 30px 0;
  }

  .product-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-price {
    background-color: $primary-color;
    color: #fff;
    border-radius: 26px;
    font-size: 34px;
    line-height: 1.5;
    padding: 0 2rem;

    &.hidden-price {
      opacity: 0;
    }
  }

  .product-add-button {
    height: 63px;
    width: 63px;
    line-height: 1;
    padding: 0;
    font-size: 58px;
    font-weight: 400;
    border-radius: 14px;
  }
}
