.card {
  position: relative;
  overflow: hidden;

  border-radius: 15px;
  padding: 24px;
  box-shadow: 0px 0px 90px #4A4A4A5C;
}

.card-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0.88;
  background-color: #f7f7f7;
}

.card-content {
    position: relative;
}
