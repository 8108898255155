@use "assets/styles/global" as *;

.home-header {
  img {
    max-height: 140px;
  }
}

.home-header, .page-content {
  position: relative;
}

.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .page-content {
    flex-grow: 1;
    position: relative;
    padding: 30px $gutter 60px;
    margin-top: 50px;

    display: flex;
    flex-direction: column;
    gap: 130px;
  }

  .home-footer {
    width: 100%;
    padding-bottom: 2rem;
    font-size: 12px;
    color: #fff;
    opacity: 0.7;
    text-align: center;

    svg {
      height: 16px;
      margin-bottom: -3px;
    }
  }
}
