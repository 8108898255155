@use "assets/styles/global" as *;

.counter {
  background-color: $primary-color;
  border-radius: 14px;
  height: 54px;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  button,
  p {
    color: #fff;
  }

  button {
    background: transparent;
    border: none;
    padding: 0 16px;
    font-size: 42px;
  }

  p {
    font-size: 36px;
    padding: 0 8px;
  }
}
