@use "assets/styles/global" as *;

.order-progress-page,
.order-success-page,
.order-failed-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.order-success-page {
  background-color: #fff;
}

.order-progress-content {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 21px;
  box-shadow: 0px 12px 48px #2e2e2e1a;
  padding: 80px;
  margin: 0 $gutter;

  text-align: center;

  p {
    font-size: 80px;
    font-weight: 500;
    color: #062849;
    position: relative;
  }

  .progress-animation {
    position: absolute;
  }
}

.order-success-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  p {
    width: 100%;
    text-align: center;
    color: #14a774;
    margin-bottom: 5rem;
    font-size: 80px;
    font-weight: 500;
  }

  .finish-button {
    flex-grow: 1;
    max-width: 400px;
    font-size: 36px;
    height: 100px;
  }
}

.order-failed-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 2rem;

  p.order-failed-title {
    font-size: 36px;
    font-weight: 500;
  }

  p {
    width: 100%;
    text-align: center;
  }

  svg {
    margin-right: 18px;
  }

  .retry-button {
    flex-grow: 1;
    max-width: 400px;
    font-size: 26px;
    height: 80px;
  }
}
