@use "assets/styles/global" as *;

.token-page {
  max-width: $page-width;
  margin: 30px auto;
}

.token-content {
  background-color: #fff;
  border-radius: 21px;
  box-shadow: 0px 24px 64px #45454514;
  padding: 45px;
  margin: 0 $gutter;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  h1 {
    font-size: 60px;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .token-error {
    color: $danger-color;
  }
}
